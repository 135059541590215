.projects-main-container {
    padding-top: 2em;
    transition: all 0.2s ease;
}

.project-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(159, 83, 240, 0.4);
    background-color: rgb(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: white;

    &:hover {
        transform: translateY(-5px);
        color: white;
        box-shadow: 0 0 10px rgba(159, 83, 240, 0.6);
    }

    .project-image {
        width: 100%;
        max-width: 350px;
        border-radius: 10px;
        object-fit: cover;
    }

    .project-title {
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 1em;
        text-decoration: underline;
    }

    .project-description {
        font-size: 1em;
        text-align: center;
        margin-top: 1em;
    }
}

.project-nav-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 2em;
}

.project-nav {
    background-color: #301E67;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    .nav-link {
        width: auto;
    }
}

.banner {
    width: 100vw;
    height: auto;
    background-size: cover;
    background-position: center;
    padding-top: 6em;
    padding-bottom: 6em;
    padding-left: 2em;
    text-align: center;

    h1 {
        font-size: 3em;
        font-weight: bold;
        color: white;
        text-shadow: -5px 5px 10px rgb(144, 54, 240);
    }
}

.gallery-image {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.kotl-container {

    h3 {
        font-size: 1.5em;
        font-weight: bold;
        text-align: start;
    }

    p {
        text-align: start;
        font-size: 1.3em;
    }

    li {
        font-size: 1.3em;
        list-style-type: none;
        text-align: start;
    }

    a {
        font-size: 1.4em;
        font-weight: 300;
        color: white;
    }

    .btn {
        font-size: 1.4em;
        font-weight: 300;
        color: white;
        background-color: #9f53f0;
        border: none;
        border-radius: 10px;
        padding: 0.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: translateY(-5px);
            color: white;
            box-shadow: 0 0 10px rgba(135, 38, 239, 0.6);
        }
    }
}

.gallery {

    img {
        border: 1px solid white;
        border-radius: 10px;
    }
}