.navbar {
    font-size: 1.2rem;
    background-color: #301E67;
    // add drop shadow to navbar
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}


.nav-link {
	text-align: center;
    white-space: nowrap;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    transition: all 0.3s;
}

.nav-link span {
    transition: all 0.3s;
}

.nav-link:before {
    content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-color: rgba(255,255,255,0.5);
	border-bottom-color: rgba(255,255,255,0.5);
	transform: scale(0.1, 1);
}

// .nav-link:hover span {
// 	// letter-spacing: 1px;
// }
.nav-link:hover::before {
	opacity: 1;	
	transform: scale(1, 1);	
}
.nav-link::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.3s;
}
.nav-link:hover::after {
	opacity: 0;	
	transform: scale(0.1, 1);
}

// .nav-link:hover {
//     color: #2448e3;
//     background: rgba($red, 0);
// }

// .navbar-nav>.nav-link {
//     // margin-left: 1.5rem;
// }

.filter-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(7500%) hue-rotate(194deg) brightness(110%) contrast(109%)
}