body {
    margin: 0;
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background-color: #301e67;
    background-image: url(../Images/backgroundGradient.svg);
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
    
}

/* Track */
::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
  background-color: #211547;
//   border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #694dbd;
  border-radius: 10px;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.App-header {
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // font-size: calc(10px + 2vmin);
    color: white;
}

#tsparticles {
  position: fixed;
  width: 100%;
  z-index: -1;
}

// @media (prefers-reduced-motion: no-preference) {
//     .App-logo {
//         animation: App-logo-spin infinite 20s linear;
//     }
// }

// @keyframes App-logo-spin {
//     from {
//         transform: rotate(0deg);
//     }

//     to {
//         transform: rotate(360deg);
//     }
// }